

* {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html, body {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

#root {
    width: 100%;
    height: 100%;
}

.btn:hover {
    color: var(--bs-btn-color) !important;
    background-color: var(--bs-btn-bg) !important;
    border-color: var(--bs-btn-border-color) !important;
}

.btn:focus {
    box-shadow: none !important;
}

