.mainContainer {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .box {
    width: 100vw;
    height: calc(100% / 7);
    padding: 0.2rem;
    button {
        font-weight: bold;
        font-size: 1.5rem;
    }
  }
}